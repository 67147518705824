function Redes() {

    return (
        <>
            <a href="https://www.instagram.com/dondequiera_que_estes/" className="">
                <button className="btn btn-white" type="button">
                    <i className="bi bi-instagram h5 instagram" />
                </button>
            </a>
        </>
    )
}

export default Redes;